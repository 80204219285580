<template>
  <div class="plat-form-rule">
    <div class="plat-ul flex flex-between">
      <div class="plat-li" @click="hrefFun('extension')">
        <img src="@/assets/img/user/platformRules/s.png" alt="" />
        <p>达人会员<br>推广法规范</p>
      </div>
      <div class="plat-li" @click="hrefFun('operation')">
        <img src="@/assets/img/user/platformRules/c.png" alt="" />
        <p>成为种草达人<br>操作方法和答疑</p>
      </div>
      <div class="plat-li" @click="hrefFun('reward')">
        <img src="@/assets/img/user/platformRules/q.png" alt="" />
        <p>种草达人<br>奖惩说明</p>
      </div>
    </div>
    <div class="plat-ul flex flex-between">
      <div class="plat-li" @click="hrefFun('sale')">
        <img src="@/assets/img/user/platformRules/2.png" alt="" />
        <p>售后说明</p>
      </div>
      <div class="plat-li" @click="hrefFun('register')">
        <img src="@/assets/img/user/platformRules/x.png" alt="" />
        <p>会员注册协议</p>
      </div>
      <div class="plat-li" @click="hrefFun('sale')">
        <img src="@/assets/img/user/platformRules/m.png" alt="" />
        <p>售后规则</p>
      </div>
    </div>
  </div>
</template>
<script src="./index.js"></script>
<style lang="scss" scoped>
@import "./index.scss";
</style>