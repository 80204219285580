export default {
    name: "PlatformRules",
    data() {
        return {
            
        }
    },
    methods: {
        hrefFun(val) {
          window.location.href = "/agreement/" + val + ".html"
        },
        onLoad() {
            // this.loading = true
        },
        showFun() {
            this.isShow = true
        }
    }
}